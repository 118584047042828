
$nav-width: 15rem;

.store-front-custom-tabs-container {
    display: flex;
    flex-direction: row;
    margin-left: -3em;
    padding: 0;
    margin-bottom: 0;

    .store-front-custom-tabs {
        position: fixed;
        height: 120vh;
        top: -5vh;
        flex-direction: column;
        width: 16vw;
        padding-top: 6em;
        background-color: var(--second-sidebar);

        .nav-item {
            margin: 1rem auto;
            width: 100%;
            text-align: center;
            $modules: #158693;
            $credit-policy: #83cdba ;
            $faq: #fab218;
            $learn-more: #b0a8fb;
            .nav-link[id$="modules"]{
                &:hover, &.active, img {
                    background-color: $modules !important;
                }
            }
            .nav-link[id$="credit-policy"]{
                &:hover, &.active, img {
                    background-color: $credit-policy !important;
                }
            }
            .nav-link[id$="faq"]{
                &:hover, &.active, img {
                    background-color: $faq !important;
                }
            }
            .nav-link[id$="learn-more"]{
                &:hover, &.active, img {
                    background-color: $learn-more !important;
                }
            }

            .nav-link {
                margin-left: 2vw !important;
                border-radius: 5px !important;
                width: 11.5vw !important;
                height: 55px !important;
                font-size: large;
                @media screen and (min-width: 1000px) and (max-width: 1600px)  {
                    font-size: medium;
                }
                text-align: left;
                background-color: white;
                color: #093d52 !important;
                border: none !important;
                opacity: 1 !important;
                transition: 0.2s;

                img {
                    padding: 2px;
                    border-radius: 5px;
                    background-color: #b5c5cb;
                }
        
                &.active, &:hover {
                    color: white !important;
                    border: none !important;
                    opacity: 1;
                    transform: none;
                }
            }
        }   
    }

    .tab-content {
        width: 100%;
        padding: 1.5em;
        padding-top: 0;
        position: absolute;
        left: 22.5vw;
        max-width: 75vw;
        overflow-x: hidden;
    }
}