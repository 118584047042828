@import './variables';

.btn {
    margin-left: 5px;
    font-family: "Sebino-Medium";
    font-weight: 400;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
    background-size: 180%;
    transition: 0.6s;
}

.btn:hover {
    transform: translateY(-3px);
}



/* Button colors */
.btn--primary{
    background-color: $primary;
    color: $eggshell; 
    border: none;   
}

.btn--secondary{
    background-color: $secondary;
    color: $almostblack;
    border: none;
}

.btn--tertiary{
    background-color: $tertiary;
    color: $almostblack;
    border: none;
}

.btn--orange{
    background-color: $pomegranate;
    color: $almostblack;
    border: none;
}

.btn--black{
    background-color: $almostblack;
    color: $eggshell; 
    border: none;
}

.btn--gold{
    background-color: $gold;
    color: $almostblack; 
    border: none; 
}


.btn--purple{
    background-color: $purple;
    color: $almostblack;  
    border: none;
}

.btn--white{
    background-color: $eggshell;
    color: $almostblack;
    border: none;
}

/* Button sizes */

.btn--small{
    padding: 8px 15px;
    font-size: 14px;
}

.btn--medium{
    padding: 10px 20px;
    font-size: 18px;
}

.btn--large{
    padding: 15px 30px;
    font-size: 25px;
}


  
.custom-btn {
    border-radius: 225px !important;
    font-size: 18px !important;
    padding: 0.4em 1.5em !important;

    &:hover {
        transform: none;
    }
    outline: 0 !important;

    &:focus, &:active {
        outline: 0 !important;
    }
}

.btn-primary {
    @extend .custom-btn;
    font-weight: 500 !important;
    border: none !important;
    background-image: linear-gradient(to top, #093d52, #158693), linear-gradient(to bottom, #83cdba, #83cdba);
    // min-width: 13rem;
    // min-height: 3rem;
    &:hover {
        box-shadow: 0px 0px 0px 400px var(--primary) inset !important;
    }

    &:focus, &:active {
        outline: 0 !important;
    }
}

.btn-outline-secondary {
    @extend .custom-btn;
    font-weight: bolder !important;
    color: var(--secondary);
    border-color: var(--secondary) !important;
    box-shadow: 0px 0px 0px 1.5px var(--secondary) inset !important;
    &:hover {
        background-color: var(--secondary);
    }
}

.btn-outline-success {
    @extend .custom-btn;
    font-weight: bolder !important;
    color: var(--tertiary);
    border-color: var(--tertiary) !important;
    // border: solid 2px var(--primary);
    box-shadow: 0px 0px 0px 1.5px var(--tertiary) inset !important;
    &:hover {
        background-color: var(--tertiary);
    }
}
