.home-page-img {
    border: 2px solid var(--secondary) !important;
    box-shadow: none !important;
    width: 25rem;
    height: 25rem;
    overflow: hidden; 
    object-fit: cover;

    @media screen and (min-width: 1000px) and (max-width: 1600px)  {
        width: 20rem;
        height: 20rem;
    }
}

.home-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    object-fit: contain;

  //background-image: linear-gradient(10deg, #0D4F63 20%, transparent 25%),
  //                  linear-gradient(350deg, #0D4F63 20%, transparent 25%),
  //                  ;
  //background-position: 0 0, 0 100%, 100% 100%, 100% 0;
//
  //background-repeat: repeat;
}

.image-row,
.card-row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.text-row{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
}

.centered-image {
    max-width: 100%;
    max-height: 100%;
  }
  
  .centered-text {
    text-align: center;
    font-size: 16px;
    margin-left: 5vw;
    margin-right: 5vw;
  }

// .image-container{
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 60%; /* Adjust based on your requirements */
//   height: 60%;
//   //margin-left: 50px;
// }

// .image-container img {
//     margin-top:35vh;
//     max-width: 100%;
//     max-height: 100%;    
//     object-fit: contain;
//   }