
//Options available for other Sebino font sizes

  @font-face {
    font-family: 'Sebino-Light';
    src: local('Sebino-Light'), url(./fonts/Sebino/Sebino-Light.ttf) format('truetype');
    font-weight: bold;
  }

  @font-face {
      font-family: 'Sebino-Regular';
      src: local('Sebino-Regular'), url(./fonts/Sebino/Sebino-Regular.ttf) format('truetype');
      font-weight: bold;
    }
  
 
  @font-face {
    font-family: 'Sebino-Bold';
    src: local('Sebino-Bold'), url(./fonts/Sebino/Sebino-Bold.ttf) format('truetype');
    font-weight: bold;
  }
  
  
  @font-face {
    font-family: 'Sebino-ExtraBold';
    src: local('Sebino-ExtraBold'), url(./fonts/Sebino/Sebino-ExtraBold.ttf) format('truetype');
    font-weight: bold;
  }
  
  @font-face {
    font-family: 'Sebino-Heavy';
    src: local('Sebino-Heavy'), url(./fonts/Sebino/Sebino-Heavy.ttf) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Sebino-Black';
    src: local('Sebino-Black'), url(./fonts/Sebino/Sebino-Black.ttf) format('truetype');
    font-weight: bold;
  }
  
@font-face {
    font-family: 'Sebino-Medium';
    src: local('Sebino-Medium'), url(./fonts/Sebino/Sebino-Medium.ttf) format('truetype');
    font-weight: bold;
  }

  b {
    font-family: 'Sebino-ExtraBold';
    font-weight: bolder;
  }

  h1 {
    font-size: 1.25rem;
    font-weight: 400;
}
h2 {
    font-size: 1.0rem;;
    font-weight: 200;
}
h3 {
    font-size: 1.25rem;
    font-weight: 400;
}
h4 {
    font-size: 1.25rem;
}
h5 {
    font-size: 1.25rem;
}
p {
    font-size: 1rem;
}
a {
    font-size: 1rem;
}

// @media (min-width: 480px) {
//   h1 {
//       font-size: 1.5rem;
//       font-weight: 400;
//   }
//   h2 {
//       font-size: 1.25rem;;
//       font-weight: 200;
//   }
// }

// @media (min-width: 768px) { 
//   h1 {
//       font-size: 1.75rem;
//       font-weight: 400;
//   }
//   h2 {
//       font-size: 1.75rem;;
//       font-weight: 200;
//   }
// }