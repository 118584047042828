// Global variables here
$primary: #093D52;
$secondary: #158693;
$tertiary: #83CDBA;

$almostblack: #021016;
$eggshell: #F8F0E9;
$purple: #B0A8FB;
$pomegranate: #EF4025;
$gold: #FAB218;
$white: #FFFFFF;

//more variants
$primary-alt: #396475;
$secondary-alt: #5BABB4;
$tertiary-alt: #A9DBCF;

$almostblack: #021016;
$eggshell-alt: #FBF6F2;
$purple-alt: #C8C3FD;
$pomegranate-alt: #F37964;
$gold-alt: #FDCA5F;