@import '../../index.scss';

div#svg-container {
    background-color: #1D1E22;
    width:100%;
    height:100%;
    display: flex;
    position: absolute;
    z-index: 10000;

    svg {
      margin: auto;
    }
}