select::-webkit-scrollbar {
    width: 9px;
    height: 0px;
  }
  select::-webkit-scrollbar-track {
    background: transparent;
  }
  select::-webkit-scrollbar-thumb {
    background: #CCC;
    border-radius: 10px;
    border-right:2px solid transparent;
    border-left:2px solid transparent;
    background-clip: padding-box;
  }