$nav-width: 15rem;

.apex-tools-heading {
    left: 24vw;
    margin-bottom: 1em;
    position: absolute;
}

.apex-tools-custom-tabs-container {
    display: flex;
    flex-direction: row;
    margin-left: -3em;
    padding: 0;
    margin-bottom: 0;

    .apex-tools-custom-tabs {
        position: fixed;
        height: 120vh;
        top: -5vh;
        flex-direction: column;
        width: 16vw;
        padding-top: 6em;
        background-color: var(--second-sidebar);

        .nav-item {
            margin: 1rem auto;
            width: 100%;
            text-align: center;
            $modules: #158693;
            $pricing-structure: #83cdba ;
            $tickets: #fab218;
            $user-management: #b0a8fb;
            $blog: #ce655e;
            .nav-link[id$="-modules"]{
                &:hover, &.active, img {
                    background-color: $modules !important;
                }
            }
            .nav-link[id$="-pricing-structure"]{
                &:hover, &.active, img {
                    background-color: $pricing-structure !important;
                }
            }
            .nav-link[id$="-tickets"]{
                &:hover, &.active, img {
                    background-color: $tickets !important;
                }
            }
            .nav-link[id$="-user-management"]{
                &:hover, &.active, img {
                    background-color: $user-management !important;
                }
            }
            .nav-link[id$="-blog"]{
                &:hover, &.active, img {
                    background-color: $blog !important;
                }
            }

            .nav-link {
                margin-left: 2vw !important;
                border-radius: 5px !important;
                width: 11.5vw !important;
                height: 55px !important;
                font-size: large;
                text-align: left;
                background-color: white;
                color: #093d52 !important;
                border: none !important;
                opacity: 1 !important;
                img {
                    padding: 2px;
                    border-radius: 5px;
                    background-color: #b5c5cb;
                }
        
                &.active, &:hover {
                    color: white !important;
                    border: none !important;
                    opacity: 1;
                    transform: none;
                }
            }
        }   
    }


    .tab-content {
        width: 100%;
        padding: 1.5em;
        position: absolute;
        left: 22.5vw;
        top: 10vh;
        max-width: 75vw;
    }
}