@import '../../styles/buttons';

.category-button {
    @extend .custom-btn;
    min-width: 8.5em;
    max-height: 2em;
    line-height: 1em;

    opacity: 0.8;
    background: lightgray;
    color: gray;

    box-shadow:  0px 2px 4px 0px rgba(44, 39, 56, 0.08), 0px 2px 2px 0px rgba(44, 39, 56, 0);
    cursor: unset !important;
}

#location-category {
    background: #fef0d1;
    color: #7d590c;
}
#network-category {
    background: #efeefe;
    color: #6a6597;
}
#it-category {
    background: #d0e7e9;
    color: #093d52;
}