
.float-right {
    float: right;
}

#details-pane-modal {
    padding: 0;
    overflow: hidden;
}

.main-info-col {
    $outer-padding: 3em;
    padding: $outer-padding;
    width: 75vw;
    margin-left: 5vw;
    .row {
        margin: auto auto 1em auto;
    }

    .title-row {
        
        margin-bottom: 2em;

        h2 {
            width: fit-content;
            margin: auto auto auto 0.5em;
        }

        img {
            max-width: $outer-padding;
            padding: 0;

            &.icon {
                background: #093d52;
                border-radius: 5px;
            }

            &.close-button {
                cursor: pointer;
            }
        }
    }

    .created-by {
    
        color: #6b8b97;
    }

    .categories {
        margin: auto;
    }
    .media-row {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
        // max-width: 80vw;
        
        .media-col {
            display: inline-block;
            max-width: 26rem;

            .module-media-card {
                width: 25rem;
                height: 25rem;  
                padding: 0;
                // margin: 0 2em;
        
                .module-media-img {
                    border: 2px solid var(--secondary) !important;
                    box-shadow: none !important;
                    width: 25rem;
                    height: 25rem;
                    overflow: hidden; 
                    object-fit: cover;
                }
            }
        }

        &::-webkit-scrollbar {
            width: 0px;
            height: 12px;
          }
          &::-webkit-scrollbar-track {
            background: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background: #CCC;
            border-radius: 10px;
            border-top:2px solid transparent;
            border-bottom:2px solid transparent;
            background-clip: padding-box;
          }
    }
}