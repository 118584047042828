.nav-tabs {
    border: 4px solid var(--second-sidebar-color) !important;
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;

    .nav-link {
        color: var(--second-sidebar-color) !important;
        opacity: 0.5 !important;
        border: none !important;
        border-color: transparent !important;
        font-size: 20px;
        font-family: 'Sebino-Bold';
        padding: 0.5em 0.5em !important;

        &:hover {
            margin-bottom: -4px;
            border: 4px solid var(--second-sidebar-color) !important;
            border-left: none !important;
            border-top: none !important;
            border-right: none !important;
        }
        &.active {
            opacity: 1 !important;
            font-weight: bolder;
            margin-bottom: -4px;
            border: 4px solid var(--second-sidebar-color) !important;
            border-left: none !important;
            border-top: none !important;
            border-right: none !important;
            // border-bottom: 4px !important;
            background: none !important;
        }
    }
}


.nav-link {
    color: var(--second-sidebar-color) !important;
    opacity: 0.5 !important;
    border: none !important;
    border-color: transparent !important;
    font-size: 20px;
    font-family: 'Sebino-Bold';
    padding: 0.5em 0.5em !important;
    

    &:hover {
        margin-bottom: -4px;
        //border: 4px solid rgb(9, 61, 82) !important;
        border-left: none !important;
        border-top: none !important;
        border-right: none !important;
        

    }
    &.active {
        opacity: 1 !important;
        font-weight: bolder;
        margin-bottom: -4px;
        //border: 4px solid rgb(9, 61, 82) !important;
        border-left: none !important;
        border-top: none !important;
        border-right: none !important;
        

        // border-bottom: 4px !important;
    }
}

