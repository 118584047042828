#create-sub-company-header.modal-header {
background-color: rgba(21, 134, 147, 0.25);
border-radius: 0;
}

.edit-company-modal-icon {
    font-size: x-large;
    color: var(--primary);
    text-decoration: none;

    &:hover {
        color: var(--primary);
        cursor: pointer;
    }
}