.error-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.return-button {
    width: 20vw;
    margin: 0 auto;
}