$transition: 0.1s all;

.module-card {
    height: 4.5em;
    border: none;
    border-left: 0.8rem solid var(--primary);
    transition: $transition;
    padding-left: 1rem;
    
    .module-name {
        color: var(--primary);   
        font-size: x-large;
        font-weight: 800;
    }

    .dropdown-col {
        text-align: end;
        .dropdown-toggle {
            background-color: #e7f4f9 !important;
            background-image: none;
            color: #6b8b97;
            border-radius: 5px !important;
            box-sizing: content-box;
    
            &:hover{
                transform: none;
                // box-shadow: none !important;
                box-shadow: 0px 0px 0px 2px var(--primary) inset !important;
            }
    
            &:focus,  &:active {
                // box-shadow: none !important;
                box-shadow: 0px 0px 0px 2px var(--primary) inset !important;
            }
    
            &[aria-expanded="true"] {
                color: var(--primary) !important;    
                // border: var(--primary) solid 2px !important;
                outline: 0;
                // box-shadow: none;
                box-shadow: 0px 0px 0px 2px var(--primary) inset !important;
            }
        }
    }
    
    .expand-toggle-col {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .expand-toggle {
            font-size: x-large !important;
            margin: auto;
            cursor: pointer;

            transition: 0.2s all;

            &.expanded {
                transform: rotate(180deg);
            }
        }
    }

    &.expanded {
        height: 9.5em;
        // height: 12.5em;
    }

    //TODO: Change these to variables
    .public {
        color: #e1a016;
    }

    .private {
        color: #b0a8fb;
    }

    .expandable-row {
        height: 0;
        white-space: nowrap;
        overflow: hidden;
        transition: $transition;
        
        .col {
            padding: 0 !important;
        }
        
        &.expanded {
            height: 100%;
        }
    }
}

