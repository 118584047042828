@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Varta:regular,bold,italic&subset=latin,latin-ext');
@import 'react-toastify/dist/ReactToastify.css';

//keeping generic scss declarations here means they get read first, and take precidence

//modal stuff------------------------------------
.modal-header {
    border-bottom: 1px solid var(--modal-header-border);
}
.modal-content {
    //color: $modal-content-color;
    background-color: var(--modal-background);
  }

  .form-select {
      background-color: var(--modal-select-edit);
      border: 1px solid var(--modal-select-border);
      color: var(--modal-contents-color);
  }
  .form-control{
    background-color: var(--modal-select-edit);
    border: 1px solid var(--modal-select-border);
    color: var(--modal-header-color);
}
.form-control:focus{
    background-color: var(--modal-select);
    border: 1px solid var(--modal-select);
    color: var(--modal-header-color);
}
.form-control[readonly]{
    background-color: var(--modal-select);
    border: 1px solid var(--modal-select);
    
}

//button 2 styling, using in ticket table dropdown
.btn-secondary {
    color: var(--ticket-dropdown-color);
    background-color: var(--ticket-dropdown-background);
    border-color: var(--ticket-dropdown-highlight);

}
    .btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
        color: var(--ticket-dropdown-color);
        background-color: var(--ticket-dropdown-background);
        border-color: var(--ticket-dropdown-highlight);
}
.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem var(--ticket-dropdown-color);;
}
.dropdown-menu-dark .dropdown-item {
    color: var(--ticket-dropdown-color);
    background-color: transparent;
}
.dropdown-item:focus-visible{
    background-color: var(--ticket-dropdown-color);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
    color: rgb(255, 0, 0);
    background-color: var(--ticket-dropdown-color);
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.15);
}

//second side bar
.nav-tabs .nav-link:hover{
    color: var(--second-sidebar-color);
}
.nav-tabs {
    color: var(--second-sidebar-color);
    background-color: var(--navTabs-backround);
    border-color: #dee2e6 #dee2e6 #fff;
}



//tickets table accordian contents
.table{
    color: var(--ticket-table-color);
    //bootstrap properties
    --bs-table-striped-color: var(--ticket-table-color);
    --bs-table-active-color: var(--ticket-table-color);
    --bs-table-hover-color: var(--ticket-table-color-hover);
}