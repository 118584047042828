#create-module-header.modal-header {
    background-color: rgba(250, 178, 24, 0.25) !important;
    border-radius: 0;
}

.create-module-button {
    margin-right: 0 !important
}

.category-col {
    cursor: pointer;
}