$sidebar-blue: #093d52;
$transition: 0.2s all;
$transition-delay: 0.5s;

.sidebar {
    text-align: center;
    flex-direction: column;
    min-height: 100vh;
    width: 5vw;
    justify-content: space-between;
    position: fixed;
    transition: $transition;
    // transition-delay: $transition-delay;
    background-color: var(--sidebar-color);
    text-align: center;
    z-index: 1500;
    border: 1px solid $sidebar-blue;
    
    &:hover {
        width: 10vw;
        box-shadow: 5px 0 10px 0px #999;
        .btn-outline-light, .username {
            & > span.nav-text, span.username-text  {
                width: 100% !important;
            }
            svg {
                margin-left: 2.5vw;
            }
            &:hover {
                color: $sidebar-blue !important;
                svg, & > span.nav-text  {
                    color: $sidebar-blue !important;
                    
                }
            }
        }
    }

    .logo {
        height: 5vh;
        width: auto;
        margin-left: 20%;
    }

    .navbar-nav{
        flex-direction: column;
        margin: auto;
        height: 50vh;
        justify-content: space-evenly;
        width: 100%;

        .icon-col {
            display: flex;
            justify-content: center;
            color: whitesmoke;
        }

        .btn-outline-light {
            margin: 0;
            border: none;
            border-radius: 0;
            height: 3rem;
            display: flex;
            // flex-direction: column;
            // justify-content: center;
            transition: $transition;
            // transition-delay: $transition-delay;
            .icon {
                margin: auto;
                margin-right: 0;
                margin-left: 1.25vw;
                font-size: x-large;
                transition-delay: 0 !important;
            }
            
            span.nav-text {
                transition: $transition;
                //transition-delay: $transition-delay;
                width: 0;
                white-space: nowrap;
                overflow: hidden;
                display: inline-block;
                margin: auto;
                margin-left: 0;
            }
        }
    }
    .wide {
        width: 100%;
        .username {
            justify-content: space-evenly;
            white-space: nowrap;
            
            .profile-button {
                margin: 0;
                border: none;
                background-color: whitesmoke;
                color: $sidebar-blue;
                border-radius: 50%;   
            }
        }

        .username-text {
            transition: $transition;
            //transition-delay: $transition-delay;
            color: whitesmoke;
            width: 0;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            margin: auto;
            margin-left: 0;
        }
    
        .login-logout-button {
            border: none;
            width: 100%;
            border-radius: 0;
            margin: 0;
        }
    }
}