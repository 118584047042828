h2 {
//    color: #333;
    font-size: 1.5em;
}

label {
    display: block;
    font-size: 1.1em;
    margin-bottom: 5px;
    color: #666;
}

#apiKey {
    width: 300px;
    padding: 5px 10px;
    margin-bottom: 15px;
    border-radius: 5px;
}

button {
    display: inline-block;
    background-color: #007BFF;
    color: #FFF;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 15px;

    &:hover {
        background-color: #0056b3;
    }
}

p {
    color: #666;
    font-size: 0.9em;
}

.warning-text {
    color: red;
    font-size: 0.8em;
    font-style: italic;
}
//{}