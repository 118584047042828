.box{
    border: black solid;
    border-radius: 5px;
    border-width: 3px;
    height: 800px;
    width:90%;
    padding-left: 5%;
    padding-right: 5%;
    
}

.outer{
    padding-left: 5%;
    padding-top: 2%;
}

.userTicketsContainer{
    margin-top: 10vh;
    border-radius: 5px;
    border-width: 2px;
    height: 60vh;
    width: 70vw;
    max-height: 540px;
    overflow-y: scroll;
 
    float: left;
}