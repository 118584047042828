@import "./styles/main.scss";

.App { 
  height: 100vh;
  font-family: 'Sebino-Regular';
}

:root {
  --primary: #093D52;
  --secondary: #158693;
  --tertiary: #83CDBA;
  --background-light: #f4f4f4;
  --almostblack: #021016;
  --eggshell: #F8F0E9;
  --purple: #B0A8FB;
  --pomegranate: #EF4025;
  --gold: #FAB218;
  --white: #FFFFFF;
  --greyBackground: #EFEEEC;

  //more variants
  --primary-alt: #396475;
  --secondary-alt: #5BABB4;
  --secondary-new: #beeff5;
  --tertiary-alt: #A9DBCF;

  --almostblack: #021016;
  --eggshell-alt: #FBF6F2;
  --purple-alt: #C8C3FD;
  --pomegranate-alt: #F37964;
  --gold-alt: #FDCA5F;
  
  //lighttheme ideas
  --light-textbox: #ecf0f2;
  --light-grey: #e4e8ea;
  --light-dark-grey: #bebebe;

  //darktheme ideas
  --dark-0: #0c122d;
  --dark-1: #1f274b;
  --dark-2: #2b3566;
  --dark-3: #2b3566d3;
  --dark-text: #b5bdc3;
  --dark-light-line: #41bfb6;
}

.main-viewport {
  $viewport-margin: 1em;
  margin-left: 5vw;
  height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--text-color);
  font-family: 'Sebino-Bold';
}



body {
  height: 100vh;
  
  //background: var(--background-color);
  //TODO: FOR TESTING REMOVE THIS

  //background color
  --bs-body-bg: var(--background-color);
  --sidebar-color: var(--primary);
  color: var(--text-color);
  font-weight: var(--font-weight);
  font-family: 'Sebino-Bold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



body.light {
  --background-color: var(--greyBackground);
  --text-color: var(--primary);
  --font-weight: 400;
  --slide-color: var(--white);
  --slide-color-header: var(--secondary-new);
  --sidebar-color: var(--primary);

  //color of navigation pills header
  --nav-pills-color: var(--light-grey);

  //modal colors
  --modal-background: var(--white);
  --modal-select: var(--light-textbox);
  --modal-select-edit: var(--white);
  --modal-select-border: var(--light-dark-grey);
  --modal-header-border: var(--light-dark-grey);
  --modal-header-color: var(--primary);
  --modal-contents-color: var(--primary);
  
  //ticket section
  --ticket-search: var(--light-grey);
  --ticket-table-header: var(--white);
  --ticket-table-buttons: var(--light-grey);
  --ticket-table-buttons2: var(--light-dark-grey);
  --ticket-table-other: var(--light-dark-grey);
  --ticket-table-contents: var(--white);
  --ticket-table-color: var(--primary);
  --ticket-table-color-hover: var(--almostblack);
  --button-select: var(black);
  
  
  //ticket dropdown
  --ticket-dropdown-color: var(--white);
  --ticket-dropdown-background: var(--dark-1);
  --ticket-dropdown-highlight: var(--dark-text);
  --ticket-dropdown-color-selected: var(--white);
  --ticket-dropdown-background-selected: var(--dark-1);
  --ticket-dropdown-highlight-selected: var(--pomegranate);
  
  //second side bar
  --second-sidebar: var(--light-grey);
  --second-sidebar-color: var(--primary);
  --bs-body-color: var(--primary);

  //nav tabs
  --navTabs-backround: var(--background-light);


  // .card {
  //   background-color: var(--primary);
  // }
}




body.dark {
  --background-color: var(--dark-0);
  --text-color: var(--dark-text);
  --text-alt: var(--dark-text);
  --font-weight: 400;
  --slide-color: var(--primary-alt);
  --slide-color-header: var(--primary);
  --sidebar-color: var(--primary);
  
  //color of navigation pills header
  --nav-pills-color: var(--dark-1);

  //modal colors
  --modal-background: var(--dark-1);
  --modal-select: var(--dark-2);
  --modal-select-edit: var(--dark-1);
  --modal-select-border: var(--dark-2);
  --modal-header-border: var(--dark-text);
  --modal-header-color: var(--dark-text);
  --modal-contents-color: var(--dark-text);

  //ticket section
  --ticket-search: var(--dark-1);
  --ticket-table-header: var(--dark-2);
  --ticket-table-buttons: var(--dark-2);
  --ticket-table-buttons2: var(--dark-2);
  --ticket-table-other: var(--dark-1);
  --ticket-table-contents: var(--dark-3);
  --ticket-table-color: var(--dark-text);
  --ticket-table-color-hover: var(--white);
  --button-select: var(--white);

  //ticket dropdown
  --ticket-dropdown-color: var(--white);
  --ticket-dropdown-background: var(--dark-1);
  --ticket-dropdown-highlight: var(--dark-text);
  --ticket-dropdown-color-selected: var(--white);
  --ticket-dropdown-background-selected: var(--dark-1);
  --ticket-dropdown-highlight-selected: var(--pomegranate);

  //second side bar
  --second-sidebar: var(--dark-1);
  --second-sidebar-color: var(--dark-text);
  --bs-body-color: var(--dark-text);

    //nav tabs
    --navTabs-backround: var(--dark-0);


  // .card {
  //   background-color: var(--almostblack);
  // }
}

//css for modals ----------------
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--nav-pills-color) !important;
  cursor: pointer;
}