.flex-direction-col {
    flex-direction: column;
}

.postal-code {
    input[type='number'] {
        -moz-appearance:textfield;
    }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

.company-label{
    color: var(--text-alt)
}