.custom-modal-dialog-right {
    height: 100vh !important;
    margin: auto 0 0 auto !important;
    overflow-y: hidden;
  }

  .custom-modal-dialog-left {
    height: 100vh !important;
    margin: 0 auto auto 0 !important;
  }

  .custom-modal-content {
    height: 100% !important;
    border-radius: 0 !important;

    .modal-header {
      color: var(--modal-header-color) !important;
      height: 90px !important;
    }

    .modal-body {
      overflow-y: scroll;

      & > form {
        & > .row {
          height: fit-content !important;
          padding-bottom: 1em;
        }
      }

      &::-webkit-scrollbar {
        width: 9px;
        height: 0px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #CCC;
        border-radius: 10px;
        border-right:2px solid transparent;
        border-left:2px solid transparent;
        background-clip: padding-box;
      }
    }
  }