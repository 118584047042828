
$transition: 0.2s ease-in-out;


.store-card {
    height: auto;
    border: none;
    transition: $transition;
    padding-left: 1rem;
    border-radius: 1em;
    background-color: white;

    .module-image-col {
        display: flex;
        //justify-content: center;
        .module-image {
            width: 100%;
            @media screen and (max-width: 1000px)  {
                max-height: 10em;
              }
              
              // Laptop
              @media screen and (min-width: 1000px) and (max-width: 1600px)  {
                max-height: 4em;
              }

              @media screen and (min-width: 1000px) and (max-width: 1600px)  {
                max-height: 4em;
              }
            
            margin: auto auto auto 0;
        }
    }
    
    .dropdown-col {
        text-align: end;
        .dropdown-toggle {
            background-color: #e7f4f9 !important;
            background-image: none;
            color: #6b8b97;
            border-radius: 5px !important;
            box-sizing: content-box;
    
            &:hover{
                transform: none;
                // box-shadow: none !important;
                box-shadow: 0px 0px 0px 2px var(--primary) inset !important;
            }
    
            &:focus,  &:active {
                // box-shadow: none !important;
                box-shadow: 0px 0px 0px 2px var(--primary) inset !important;
            }
    
            &[aria-expanded="true"] {
                color: var(--primary) !important;    
                // border: var(--primary) solid 2px !important;
                outline: 0;
                // box-shadow: none;
                box-shadow: 0px 0px 0px 2px var(--primary) inset !important;
            }
        }
    }
        &.expanded {
        height: auto;
    }

    .public {
        color: #e1a016;
    }

    .private {
        color: #b0a8fb;
    }

    .left-border-col {
        border-left: solid var(--primary) 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: $transition;

        .module-name-row {
            color: var(--primary);   
            font-size: x-large;
            font-weight: 800;
            cursor: pointer;
            user-select: none;
            padding-left: 0.5em;
    
            .expand-toggle {
                font-size: x-large !important;
                margin: auto;
                cursor: pointer;
    
                transition: $transition;
    
                &.expanded {
                    transform: rotate(180deg);
                }
            }
        }

        .expandable-row {
            white-space: nowrap;
            overflow: hidden;
            transition: $transition;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            font-size: 0;

    
            .description-col {
                background: #f8f8f8;
                border-radius: 1em;
                padding: 0 1em;
                margin-left: 1em;
                margin-right: 1em;
                margin-bottom: 1em;  
                max-height: 0;
                overflow-wrap: break-word;
                white-space: initial;
                overflow-y: scroll;
                font-size: 0;
                font-size: medium;
                transition: $transition;
                
                &::-webkit-scrollbar {
                    width: 9px;
                    height: 0px;
                }
                &::-webkit-scrollbar-track {
                    background: transparent;
                }
                &::-webkit-scrollbar-thumb {
                    background: #CCC;
                    border-radius: 10px;
                    border-right:2px solid transparent;
                    border-left:2px solid transparent;
                    background-clip: padding-box;
                }

                &.expanded {
                    padding: 1em;
                    margin-top: 1em;
                    max-height: 10em;

                    
                }
            }
            

        }    

        .bottom-row {
            margin-top: 1em;
            .created-by {
                margin-left: 0.75em;
                color: #6b8b97;
            }

            .open-solution-button {
                height: 2em !important;
                line-height: 1em !important;
                font-size: 1em !important;
                white-space: nowrap;
            }

            .categories {
                margin: auto;
            }
        }
    }
}

